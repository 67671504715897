<template>
    <base-card class="h-full">
        <v-card-title class="d-flex justify-space-between pt-1">
            <div class="mb-0 mr-4" style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                <p class="card-title mb-0"><span @click="activateTitle" :class="secondTitleActive ? 'disabled' : ''">{{ title }}</span><span @click="activateSecondTitle" v-if="secondTitle"> | <span :class="secondTitleActive ? '' : 'disabled'"> {{ secondTitle }}</span></span></p>
                <span>
                    <span v-if="showOptionChevron" @click="showHeaderActions = !showHeaderActions">
                        <v-icon style="cursor: pointer;">{{ showHeaderActions ? 'mdi-chevron-up-circle-outline' : 'mdi-chevron-down-circle-outline' }}</v-icon>
                    </span>
                    <span class="ml-1">
                        <v-icon @click="expand = !expand; $emit('expand');" style="cursor: pointer;">
                            {{ expand ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}
                        </v-icon>
                    </span>
                </span>
            </div>
            <div class="mb-0 mr-4" style="width: 100%;" v-show="headerActionsDisplayed">
                <slot name="headerActions"></slot>
            </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <slot name="chart"> </slot>
        </v-card-text>
        <div class="mt-0 mb-2 ml-2">
            <slot name="footerActions"> </slot>
        </div>
    </base-card>
</template>
<script>

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        secondTitle: {
            type: String,
            default: null
        },
        showOptionChevron: {
            type: Boolean,
            default: false
        },
        defaultOpenOptions: {
            type: Boolean,
            default: false
        }
    },
    mounted(){
        this.showHeaderActions = this.defaultOpenOptions;
    },
    data(){
        return {
            showHeaderActions: false,
            secondTitleActive: false,
            expand: false
        }
    },
    computed: {
        headerActionsDisplayed(){
            if(!this.showOptionChevron) return true;
            return this.showHeaderActions;
        }
    },
    methods:{
        activateTitle(){
            this.secondTitleActive = false;
            this.$emit("titleActive");
        },
        activateSecondTitle(){
            this.secondTitleActive = true;
            this.$emit("secondTitleActive");
        }
    }
}
</script>

<style scoped>
.disabled {
    color: #666;
    cursor: pointer;
    text-decoration-line: underline;
}
</style>
